.navbar {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  background: black;
  display: flex;
  align-items: center;
  height: 75px;
}

.navbar__logo img {
  margin: 0;
  width: 300px;
}

.navbar__items {
  margin: auto;
}

.navbar__spacer {
  width: 300px;
}

.navbar__item ul {
  display: inline-flex;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0px;
  height: 100%;
  color: #ffffff;
}

.navbar__item li
{
  padding: 0 0 0 0;
  margin: 0;
  text-align: center;
  width: 150px;
  margin: 0px;
}

.navbar__item li:hover {
  color: #ec6608;
}

.navbar__item a,
.navbar__item button
{
  height: 100%;
  width: 100%;
  margin: 0;
  text-decoration: none;
  border: none;
  font: inherit;
  background: transparent;
  cursor: pointer;
}

.navbar__item a:link,
.navbar__item button:link {
  text-decoration: none;
}

.navbar__item a:hover,
.navbar__item button:hover {
  color: #ec6608;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
.navbar {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    background: black;
    display: inline-block;
    align-items: center;
    height: 75px;
    transition: top 0.3s;
}

.navbar__logo img {
    width: 100%;
    align-items: center;
}

.navbar__items {
    margin: auto;
}

.navbar__login {
    width: 100%;
}

.navbar__item ul {
    display: inline-block;
    width: 100%;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0px;
}

.navbar__item li
{
    padding: 0 0 0 0;
    margin: 0;
    text-align: center;
    width: 100%;
    margin: 0px;
    min-height: 40px;
}

#btn_logout {
    width: 100%;
}
}
