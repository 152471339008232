.modal {
  width: 90%;
  background: white;
  box-shadow: 0 px 8px rgba(0,0,0,0.26);
  position: fixed;
  top: 20vh;
  left: 5%;
  z-index: 2147483647;
}

.modal__header {
  padding: 1rem;
  background: #f07f3c;
}

.modal__header h1 {
  margin: 0;
  font-size: 1.25rem;
}

.modal__content {
  padding: 1rem;
}

.modal__actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

@media(min-width: 768px) {
  .modal {
    width: 30rem;
    left: calc((100% - 30rem) / 2);
  }
}