.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #96afbe;
}

a {
  color: inherit;
}

button {
  margin: 2px;
  min-height: 40px;
  min-width: 100px;
  background: #4c96c4;
  color: white;
  border: none;
}

button:hover {
  background: #96afbe;
}

.main-content {
  background: white;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  min-height: 100vh;
}

label {
  display: inline-block;
}


.label__value {
  margin: 0px;
  border: 1px solid #acacac;
  border-radius: 2px;
  height: 25px;
  width: 100%;
  padding: 5px 0 0 0;
}

h3 {
  margin: 0px;
}

h4 {
  margin: 0px;
}

input,
select {
  margin: 0px;
  border: 1px solid #acacac;
  border-radius: 2px;
  height: 37px;
}

input:disabled,
select:disabled {
  background-color: #efefef;
}
@media only screen and (min-width: 1500px) {
.main-content {
  background: white;
  max-width: 50%;
  margin: 0 auto;
  padding: 10px;
  min-height: 100vh;
}
}