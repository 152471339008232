.task_item {
  border-radius: 5px;
  box-shadow: 1px 1px #acacac;
  display: flex;
  margin: 5px;
  padding: 5px;
}

.task_title {
  width: 200px;
}

.task_label {
  border-radius: 5px;
  width: max-content;
}