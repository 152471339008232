.user_info {
  margin: 0;
  display: grid;
}

@media only screen and (min-width: 1500px) {
.user_info {
  position: absolute;
  top: 75px;
  left: 0;
  background: white;
  padding: 10px;
  margin: 10px;
  width: 20%
}
}